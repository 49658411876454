.page-body {
    background: #1C1C1C;
    padding: 5px;
    margin: 0;
}

.app {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.profile--picture {
    width: 317px;
    height: 317px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.profile {
    display: flex;
    justify-content: center;
}

.profile--information--name {
    background: #1A1B21;
    font-size: 25px;
    font-family: Inter, serif;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.profile--information--job {
    background: #1A1B21;
    font-size: 15px;
    font-family: Inter, serif;
    font-weight: normal;
    color: #F3BF99;
    margin: 0 auto;
    padding-top: 3px;
    display: flex;
    justify-content: center;
}

.profile--information--email {
    background: #1A1B21;
    font-size: 12px;
    font-family: Inter, serif;
    font-weight: normal;
    color: #F5F5F5;
    margin: 0 auto;
    padding-top: 7px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
}

.profile--information--contact {
    background: #1A1B21;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
}

.profile--information--contact--button--email {
    background: #FFFFFF;
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    padding: 5px 25px;
    font-family: Inter, serif;
    font-size: 15px;
}

.profile--information--contact--button--email > a {
    color: #374151;
    text-decoration: none;
}

.profile--information--contact--button--email > a:hover {
    opacity: 80%;
}

.profile--information--contact--button--linkedin {
    background: #5093E2;
    border-radius: 5px;
    display: flex;
    margin-left: 5px;
    padding: 5px 15px;
    font-family: Inter, serif;
    font-size: 15px;
}

.profile--information--contact--button--linkedin > a {
    color: #FFFFFF;
    text-decoration: none;
}

.profile--information--contact--button--linkedin > a:hover {
    opacity: 80%;
}

.main--content--page {
    background: #1A1B21;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    width: 317px;
    white-space: pre-line;
}

.main--content--page--text {
    color: #F5F5F5;
    font-family: Inter, serif;
    font-size: 15px;
    font-weight: normal;
    padding: 15px 10px 10px;
    padding-inline: 25px;
}

.main--content--page--text > h1 {
    margin: 0;
    padding-bottom: 2px;
    font-size: 16px;
}
.main--content--page--text > p {
    margin: 0;
    letter-spacing: 0.4px;
    font-size: 11px;
}

.footer {
    width: 317px;
    background: #161619;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.footer--content--social {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
}

.footer--content--button {
    margin: 10px auto;
    color: #918E9B;
}

.footer--content--social--icon {
    width: 30px;
    height: 30px;
}

.footer--content--button:hover {
    opacity: 80%;
}